export const MainConfig = {
    AppDetails: {
        app_name: "GrabCab", 
        app_description: "GrabCab Taxi Booking App",
        app_identifier: "com.exicube.grabcab",
        ios_app_version: "2.5", 
        android_app_version: 25
    },
    FirebaseConfig: {
        apiKey: "AIzaSyBJCExRxdgQb15BKmnBodL-FgXYpVxnTfo",
    authDomain: "grab-fbd76.firebaseapp.com",
    databaseURL: "https://grab-fbd76-default-rtdb.firebaseio.com",
    projectId: "grab-fbd76",
    storageBucket: "grab-fbd76.appspot.com",
    messagingSenderId: "625058535945",
    appId: "1:625058535945:web:e5d81a25709021ea30b6d8",
    measurementId: "G-GJLEBYM63J"

    },
    Google_Map_Key: "AIzaSyCmbrliQB52pbSHEk9aranBmAvGrmx9PXk",
    facebookAppId: "3966457783378747"
}